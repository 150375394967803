export enum ContentfulContentTypes {
    SOCIAL_NETWORKS = "socialNetworks",
    SHARE_NETWORKS = "shareIcons",
    SOCIAL_NETWORK = "socialNetwork",
    LOGO = "logo",
    HERO = "hero",
    CAUSES = "causes",
    CAUSE = "cause",
    FOOTER = "footer",
    GENERIC_ICONS = "genericIcons"
}