import React, { useState } from 'react';
import { useEffect } from 'react';

// Assets
import '../assets/styles/Hero.css';

export const Hero = (props: any) => {

    const { Data } = props;
    const [heroData, setHeroData] = useState(undefined);
 
    useEffect(() => {
        setHeroData(Data)
    }, [Data])

    if (heroData)
        return (
            <section id="Hero" style={{ backgroundImage: `url(${heroData.heroImageText})`}} title={`${heroData.header_1}. ${heroData.header_2}. ${heroData.header_3}. ${heroData.subHeader}`} />
        )
    else
        return (
            <section id="Hero" className="Hero-loading" />
        )
}
