import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Assets
import '../assets/styles/HeaderBar.css';
import { GenericIconTypes } from '../enums/GenericIconTypes';
import { ChildCallbackTypes } from '../enums/ChildCallbackTypes';

export const HeaderBar = (props: any) => {

    const { causeName } = useParams();
    const { SocialNetworks, Title, Icons, NotifyParent } = props;
    const history = useHistory();
    const [socialNetworks, setSocialNetworks] = useState(undefined);
    const [title, setTitle] = useState(undefined);
    const [icons, setIcons] = useState(undefined);
    const [optionsIcon, setOptionsIcon] = useState(undefined);

    const GetOptionsIcon = () => {
        (icons || []).forEach((icon: any) => {
            if (icon.fields.title === GenericIconTypes.OPTIONS)
                setOptionsIcon(icon.fields.file.url);
        })
    }

    useEffect(() => {
        setSocialNetworks(SocialNetworks);
    }, [SocialNetworks])

    useEffect(() => {
        setTitle(Title);
    }, [Title])

    useEffect(() => {
        setIcons(Icons);
    }, [Icons])

    useEffect(() => {
        GetOptionsIcon();
    }, [icons])

    return (
        <header className={ causeName && 'full-width' }>
            <section id="Header-logo-section">
                <h1 className="HeaderBar-normal-logo" onClick={() => history.push("/")}>
                    <span>Choice</span>
                    <span>Voice</span>
                    <span>Vote</span>
                </h1>
                <h1 className="HeaderBar-mobile-logo" onClick={() => history.push("/")}>
                    <span style={{ fontSize: '2.3rem' }}>C</span>
                    <span style={{ fontSize: '2.3rem' }}>V</span>
                    <span>V</span>
                </h1>
                {
                    title
                    &&
                    <h2>{ title }</h2>
                }
            </section>
            <section id="Header-options-social-section">
                {
                    causeName
                    &&
                    <figure id="Header-cause-name-container">
                        <span>{ causeName.split("_").join(" ") }</span>
                    </figure>
                }
                {
                    causeName
                    &&
                    <article id="Header-filter-container" onClick={() => NotifyParent(ChildCallbackTypes.SET_FILTER_MENU, true)}>
                        <span>Filter action tiles</span>
                        {
                            optionsIcon
                            &&
                            <img src={optionsIcon} />
                        }
                    </article>
                }
                {
                    socialNetworks
                    &&
                    socialNetworks.length > 0
                    &&
                    <section>
                        {
                            socialNetworks.map((socialNetwork: any, index: number) => (
                                <a href={socialNetwork.url} target="_blank" key={index} rel="noorigin noreferrer"><img className="HeaderBar-social-icon" src={socialNetwork.icon.fields.file.url} /></a>
                            ))
                        }
                    </section>
                }
            </section>
        </header>
    )
}
