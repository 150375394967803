import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from 'react-router-dom';

// Components
import * as serviceWorker from './serviceWorker';
import { HomePage } from './views/home-page/HomePage';
import { CausePage } from './views/cause-page/CausePage';
import { App } from './components/App';

// Assets
import './index.css';

ReactDOM.render(
    <Router>
        <App>
            <Switch>
                <Route exact path="/">
                    <HomePage />
                </Route>
                <Route exact path="/home">
                    <Redirect to="/" />
                </Route>
                <Route exact path="/:causeName?/:sponsor?">
                    <CausePage />
                </Route>
            </Switch>
        </App>
    </Router>
, document.getElementById('root'));

// Prevent offline usage
serviceWorker.unregister();
