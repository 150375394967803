import React, { useEffect, useState } from 'react';

// Assets
import '../assets/styles/Footer.css';

export const Footer = (props: any) => {

    const { SocialNetworks, Logo, FooterData } = props;
    const [socialNetworks, setSocialNetworks] = useState(undefined);
    const [logo, setLogo] = useState(undefined);
    const [footerData, setFooterData] = useState(undefined);

    useEffect(() => {
        setSocialNetworks(SocialNetworks);
    }, [SocialNetworks])

    useEffect(() => {
        setLogo(Logo);
    }, [Logo])

    useEffect(() => {
        setFooterData(FooterData);
    }, [FooterData])

    return (
        <footer style={{ backgroundImage: `url(${footerData})`}}>
            {/* {
                socialNetworks
                &&
                <section style={{ width: `${socialNetworks.length * 2.6}rem`}}>
                    {
                        socialNetworks.map((socialNetwork: any, index: number) => (
                            <a href={socialNetwork.url} target="_blank" key={index} rel="noorigin noreferrer"><img className="Footer-social-icon" src={socialNetwork.iconWhite.fields.file.url} /></a>
                        ))
                    }
                </section>
            } */}
            <p>Brought to you by:</p>
            {
                logo
                &&
                <a href="http://farstaractionfund.org/" target="_blank" rel="noorigin noreferrer">
                    <img id="Footer-logo" src={logo} alt="CVV Logo" />
                </a>
            }
        </footer>
    )
}