import React, { useEffect, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
import { useParams } from 'react-router';
import MetaTags from 'react-meta-tags';

// Components
import { HeaderBar } from '../../components/HeaderBar';
import { AboutPanel } from '../../components/AboutPanel';

// Assets
import './CausePage.css';
import { contentfulClient } from './../../utils/ContentfulClient';
import { ContentfulObject } from './../../interfaces/Contentful/ContentfulObject';
import { ContentfulContentTypes } from '../../enums/ContentfulContentTypes';
import { Cause } from '../../interfaces/Cause';
import { LogoTypes } from '../../enums/LogoTypes';
import { FilterMenu } from '../../components/FilterMenu';
import { ChildCallbackTypes } from '../../enums/ChildCallbackTypes';
import { SubCauses } from '../../components/SubCauses';
import { SubCauseTypes } from '../../enums/SubCauseTypes';

export const CausePage = () => {

    const { causeName, sponsor } = useParams();
    const [cause, setCause] = useState(undefined);
    const [subCauses, setSubCauses] = useState([]);
    const [socialNetworks, setSocialNetworks] = useState([]);
    const [shareNetworks, setShareNetworks] = useState([]);
    const [logo, setLogo] = useState(undefined);
    const [loading, setLoading] = useState(true);
    const [icons, setIcons] = useState(undefined);
    const [displayFilter, setDisplayFilter] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(SubCauseTypes.ALL);

    // Grab relevant contentful data
    const GetData = async () => {
        try {
            setLoading(true);
            const response = await contentfulClient.getEntries();
            await new Promise(resolve => {
                response.items.forEach((item: ContentfulObject) => {
                    switch (item.sys.contentType.sys.id) {
                        case ContentfulContentTypes.CAUSES:
                            item.fields.causes.forEach((causeItem: any) => {
                                if (causeName.toLowerCase() == causeItem.fields.title.split(' ').join('_').toLowerCase()) {
                                    let cause: Cause = {
                                        imgUrl: causeItem.fields.image.fields.file.url,
                                        title: causeItem.fields.title,
                                        description: causeItem.fields.description,
                                        aboutDescription: causeItem.fields.aboutDescription ? causeItem.fields.aboutDescription : undefined,
                                        aboutImage: causeItem.fields.aboutImage ? causeItem.fields.aboutImage.fields.file.url : undefined,
                                        sponsors: causeItem.fields.sponsors ? causeItem.fields.sponsors : undefined,
                                        subCauses: causeItem.fields.children ? causeItem.fields.children : undefined
                                    }
                                    setCause(cause);
                                }
                            })
                            break;
                        case ContentfulContentTypes.SOCIAL_NETWORKS:
                            item.fields.network.forEach((socialNetwork: any) => {
                                if (socialNetwork.fields.active)
                                    setSocialNetworks(socialNetworks => [...socialNetworks, socialNetwork.fields])
                            });
                            break;
                        case ContentfulContentTypes.SHARE_NETWORKS:
                            item.fields.icons.forEach((shareNetwork: any) => {
                                if (shareNetwork.fields.active)
                                    setShareNetworks(shareNetworks => [...shareNetworks, shareNetwork.fields])
                            });
                            break;
                        case ContentfulContentTypes.LOGO:
                            item.fields.logos.forEach((logo: any) => {
                                if (logo.fields.title === LogoTypes.LOGO_COLOR)
                                    setLogo(logo.fields.file.url)
                            });
                            break;
                        case ContentfulContentTypes.GENERIC_ICONS:
                            setIcons(item.fields.icons);
                            break;
                    }
                })
                resolve();
        })
        setLoading(false);
        } catch(e) {
            setLoading(false);
            console.log(e);
        }
    }

    const ChildCallback = (type: ChildCallbackTypes, value: any) => {
        switch (type) {
            case ChildCallbackTypes.SET_FILTER_MENU:
                setDisplayFilter(value);
                break;
            case ChildCallbackTypes.SET_FILTER_VALUE:
                setSelectedFilter(value);
                break;
        }
    }

    useEffect(() => {
        GetData();
    }, [])

    return (
        <React.Fragment>
            {
                cause
                &&
                <MetaTags>
                    <title>{ `${cause.title} - Choice Voice Vote`}</title>
                    <meta name="Description" content={cause.description} ></meta>
                    <meta property="og:locale" content="en_CA"></meta>
                    <meta property="og:type" content="website"></meta>
                    <meta property="og:title" content={cause.title}></meta>
                    <meta property="og:description" content={cause.description}></meta>
                    <meta property="og:url" content={window.location.href}></meta>
                    <meta property="og:site_name" content={`${cause.title} - Choice Voice Vote`}></meta>
                    <meta property="og:image" content={`${cause.imgUrl}`}></meta>
                    <meta property="og:image:secure_url" content={`${window.location.href}`}></meta>
                    <meta property="og:image:width" content="208"></meta>
                    <meta property="og:image:height" content="208"></meta>
                    <meta name="twitter:card" content="summary_large_image"></meta>
                    <meta name="twitter:description" content={cause.description}></meta>
                    <meta name="twitter:title" content={cause.title}></meta>
                    <meta name="twitter:site" content="@"></meta>
                    <meta name="twitter:image" content={cause.imgUrl}></meta>
                    <meta name="twitter:creator" content="@"></meta>
                </MetaTags>
            }

            {
                loading
                ?
                <LoadingBar
                    height={3}
                    color='#003B71'
                    onRef={(ref: any) => { if (ref) ref.continousStart() } }
                />
                :
                <React.Fragment>
                    <HeaderBar SocialNetworks={socialNetworks} Title={cause ? cause.title : undefined} Icons={icons} NotifyParent={ChildCallback} />
                    <FilterMenu DisplayFilter={displayFilter} NotifyParent={ChildCallback} Icons={icons} />
                    <section style={{ display: 'flex', margin: '0 auto' }}>
                        <AboutPanel Cause={cause} Logo={logo} SocialNetworks={socialNetworks} Icons={icons} ShareNetworks={shareNetworks} />
                        <SubCauses Cause={cause} Filter={selectedFilter} Logo={logo} Icons={icons} SocialNetworks={socialNetworks} FilterVisible={displayFilter} />
                    </section>
                </React.Fragment>
            }

        </React.Fragment>
    )
}