import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import Masonry from 'react-masonry-component';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// Enums
import { SubCauseTypes } from '../enums/SubCauseTypes';
import { AnimationTypes } from '../enums/AnimationTypes';
import { SocialNetworkTypes } from '../enums/SocialNetworkTypes';
import { GenericIconTypes } from '../enums/GenericIconTypes';

// Assets
import '../assets/styles/SubCauses.css';
import { ArrowIcon } from './icons/ArrowIcon';
import ToTitleCase from '../utils/ToTitleCase';

export const SubCauses = (props: any) => {

    const getGa = ()=> ((window as any).ga);

    const { sponsor } = useParams();
    const { Cause, Filter, Logo, SocialNetworks, FilterVisible, Icons } = props;
    const [subCauses, setSubCauses] = useState([]);
    const [filteredSubCauses, setFilteredSubCauses] = useState([]);
    const [causeSponsor, setCauseSponsor] = useState(undefined);
    const [filterType, setFilterType] = useState(SubCauseTypes.ALL);
    const [socialNetworks, setSocialNetworks] = useState(undefined);
    const [logo, setLogo] = useState(undefined);
    const [icons, setIcons] = useState(undefined);
    const [shareIcon, setShareIcon] = useState(undefined);
    const [linkIcon, setLinkIcon] = useState(undefined);
    const [shareMenuVisible, setShareMenuVisible] = useState(false);
    const [showCopyMessage, setShowCopyMessage] = useState(false);
    const [currentClass, setCurrentClass] = useState("");
    const [animatingCopy, setAnimatingCopy] = useState(false);
    const [copiedToClipboard, setCopiedToClipboard] = useState(false);
    const [loading, setLoading] = useState(true);

    const FindCauseSponsor = (cause: any) => {
        (cause && cause.sponsors || []).forEach((causeSponsor: any) => {
            if (causeSponsor.fields.name.toLowerCase() === sponsor.split('_').join(' ').toLowerCase())
                setCauseSponsor(causeSponsor.fields);
        })
    }

    const GetIcons = (icons: any) => {
        (icons || []).forEach((icon: any) => {
            if (icon.fields.title === GenericIconTypes.SHARE)
                setShareIcon(icon.fields.file.url);
            if (icon.fields.title === GenericIconTypes.LINK)
                setLinkIcon(icon.fields.file.url);
        })
    }

    const GetSubCauses = (cause: any) => {
        (cause && cause.subCauses || []).forEach((subCause: any) => {
            setSubCauses(subCauses => [...subCauses, subCause.fields]);
        });
    }

    const GetFilteredSubCauses = () => {
        if (filterType === SubCauseTypes.ALL) {
            setFilteredSubCauses(subCauses);
            return;
        }

        let filteredList = [];
        (subCauses || []).forEach((item: any) => {
            if (item.type.fields.type === filterType)
                filteredList.push(item);
        })

        setFilteredSubCauses(filteredList);
    }

    const AnimateClipboardCopy = () => {
        setCopiedToClipboard(true);

        if (animatingCopy)
            return;

        setAnimatingCopy(true);
        setShowCopyMessage(true);
        setCurrentClass(AnimationTypes.FADE_IN_NORMAL);
        setTimeout(() => {
            setCurrentClass(AnimationTypes.FADE_OUT_NORMAL);
        }, 1000);
        setTimeout(() => {
            setShowCopyMessage(false);
            setCurrentClass("");
            setAnimatingCopy(false);
            setCopiedToClipboard(false);
        }, 2000)
    }

    const GetSocialNetworkShareLink = (networkName: any): string => {
        switch (networkName) {
            case SocialNetworkTypes.FACEBOOK:
                return `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`;
            case SocialNetworkTypes.INSTAGRAM:
                break;
            case SocialNetworkTypes.TWITTER:
                return `https://twitter.com/intent/tweet?text=${window.location.href}`;
            case SocialNetworkTypes.MEWE:
                return `https://mewe.com/myworld`;
            default:
                return ``                
        }
    }

    useEffect(() => {
        setSocialNetworks(SocialNetworks);
    }, [SocialNetworks])

    useEffect(() => {
        setLogo(Logo);
    }, [Logo])

    useEffect(()=>{
        GetSubCauses(Cause);
        if (sponsor)
            FindCauseSponsor(Cause);
    }, [Cause]);

    useEffect(() => {
        GetIcons(Icons);
    }, [Icons])

    useEffect(() => {
        GetFilteredSubCauses();
    }, [subCauses])

    useEffect(() => {
        setFilterType(Filter);
    }, [Filter])

    useEffect(() => {
        GetFilteredSubCauses();
    }, [filterType])

    useEffect(() => {
        setLoading(false);
    }, [filteredSubCauses]);




    return (
        <section id="SubCauses" className={FilterVisible ? `dont-display` : `display`}>
            {
            <React.Fragment>
                {
                    Cause
                    &&
                    <section id="SubCauses-cause-overview">
                        <div style={{ background: 'none', marginBottom: '1rem', paddingBottom: 0 }}>
                            <h4>Cause Overview</h4>
                            {
                                Cause
                                &&
                                <React.Fragment>
                                    <img src={Cause.imgUrl} id="AboutPanel-main-image" />
                                    <h1>{ Cause.title }</h1>
                                    <p>{ Cause.description }</p>
                                    {
                                        !sponsor
                                        &&
                                        <React.Fragment>
                                            <img src={Cause.aboutImage} />
                                            <p>{ Cause.aboutDescription }</p>
                                        </React.Fragment>
                                    }
                                    {
                                        shareMenuVisible
                                        ?
                                        <article id="AboutPanel-share-section-visible" className="animated fadeIn">
                                            <span>Share cause</span>
                                            <figure>
                                                <article>
                                                    <CopyToClipboard text={window.location.href} onCopy={() => AnimateClipboardCopy()}>
                                                        <img src={linkIcon} title="Copy link" className="AboutPanel-share-image" style={{ width: '1.25rem' }} />
                                                    </CopyToClipboard>
                                                    {
                                                        copiedToClipboard
                                                        &&
                                                        showCopyMessage
                                                        &&
                                                        <figure id="AboutPanel-copy-popup" className={currentClass}>
                                                            <span>Copied</span>
                                                        </figure>
                                                    }
                                                </article>
                                                {
                                                    socialNetworks.map((socialNetwork: any, index: number) => (
                                                        <React.Fragment key={index+socialNetwork.name}>
                                                            {
                                                                socialNetwork.name !== SocialNetworkTypes.INSTAGRAM
                                                                &&
                                                                <a href={GetSocialNetworkShareLink(socialNetwork.name)} target="_blank" key={index+socialNetwork.name} rel="noorigin noreferrer"><img src={socialNetwork.icon.fields.file.url} title={`Share on ${socialNetwork.name}`} /></a>
                                                            }
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </figure>
                                        </article>
                                        :
                                        <article id="AboutPanel-share-section" onClick={() => setShareMenuVisible(true)} style={{ background: 'rgba(255, 255, 255, 0)' }}>
                                            <span>Share cause</span>
                                            <img src={shareIcon} />
                                        </article>
                                    }
                                    {
                                        sponsor
                                        &&
                                        causeSponsor
                                        &&
                                        <figure id="AboutPanel-cause-sponsor">
                                            <p>Cause Sponsor</p>
                                            <h1>{ causeSponsor.name }</h1>
                                            <a href={causeSponsor.url} target="_blank" rel="noorigin noreferrer">{ causeSponsor.url }</a>
                                            <img src={causeSponsor.image.fields.file.url} />
                                        </figure>
                                    }
                                </React.Fragment>
                                }
                            </div>
                        </section>
                    }
                    <Masonry
                        className={''}
                        elementType={'article'}
                        updateOnEachImageLoad={true}
                    >
                        {
                            causeSponsor
                            &&
                            !loading
                            &&
                            (
                                <article className="SubCauses-item SubCauses-sponsor grid-item grid-item--width2">
                                    <section>
                                        <h3>{ `sponsor`.toUpperCase() }</h3>
                                    </section>
                                    <a href={causeSponsor.url} target="_blank" rel="noorigin noreferrer" style={{ padding: 0 }}>
                                        <img src={causeSponsor.image.fields.file.url} className="grid-item--width2" style={{ height: 'auto' }} />
                                    </a>
                                    <div>
                                        <h3>{causeSponsor.name}</h3>
                                        <p>{causeSponsor.description}</p>
                                    </div>
                                    <article>
                                        <ArrowIcon />
                                        <a href={causeSponsor.url} target="_blank" rel="noorigin noreferrer">{ causeSponsor.name }</a>
                                    </article>
                                </article>
                            )
                        }
                        {
                            !loading
                            &&
                            (filteredSubCauses || []).map((subCause: any, index: any) => (
                                <article className={`SubCauses-item SubCauses-${subCause.type.fields.type.toLowerCase()} ${subCause.large ? `grid-item grid-item--width2` : `grid-item`} ${subCause.imageOnly ? `grid-item--height2` : ``}`} key={subCause.title + index}>
                                    <section>
                                        <h3>{ subCause.type.fields.type.toUpperCase() }</h3>
                                        {
                                            subCause.icon.fields.icon
                                            &&
                                            <img src={subCause.icon.fields.icon.fields.file.url} />
                                        }
                                    </section>
                                    <a href={subCause.actionLink} target="_blank" style={{ padding: 0, width: '100%' }}>
                                        {/* <figure style={{ backgroundImage: `url(${subCause.image.fields.file.url})`}} /> */}
                                        <img src={subCause.image.fields.file.url} className={subCause.large ? `grid-item grid-item--width2` : `grid-item`} style={{ height: 'auto', width: '100%' }} />
                                    </a>
                                    {
                                        !subCause.imageOnly
                                        &&
                                        <div>
                                            <h3>{subCause.title}</h3>
                                            <p>{ subCause.description }</p>
                                        </div>
                                    }
                                    {
                                        !subCause.imageOnly
                                        &&
                                        <article>
                                            <ArrowIcon className={`${subCause.type.fields.type}-color-svg`} />
                                            <a href={subCause.actionLink} target="_blank">{ subCause.actionLinkText }</a>
                                        </article>
                                    }
                                </article>
                            ))
                        }
                    </Masonry>
                    <footer id="MobileFooter">
                        <h5>Brought to you by:</h5>
                        <figure id="AboutPanel-logo-container">
                            {
                                logo
                                &&
                                <a href="http://farstaractionfund.org/" target="_blank" rel="noorigin noreferrer">
                                    <img id="Footer-logo" src={logo} alt="CVV Logo" style={{ width: '8rem', height: '55px' }} />
                                </a>
                            }
                        </figure>
                        {
                            socialNetworks
                            &&
                            <section style={{ width: `${socialNetworks.length * 2.6}rem`}}>
                                {
                                    socialNetworks.map((socialNetwork: any, index: number) => (
                                        <a href={socialNetwork.url} target="_blank" key={index} rel="noorigin noreferrer"><img className="Footer-social-icon" src={socialNetwork.icon.fields.file.url} /></a>
                                    ))
                                }
                            </section>
                        }
                    </footer>
            </React.Fragment>
            }
        </section>
    )
}