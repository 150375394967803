import React, { useEffect } from 'react';
import { useHistory } from 'react-router';


export const App = ({ children }) => {
    const { listen } = useHistory();

    const getGa = ()=> ((window as any).ga);

    const handlePageView = (pathname)=> {
        const _ga = getGa();
        _ga('set', 'page', pathname);
        _ga('send', 'pageview');
    }

    useEffect(()=>{
        // Register any views after initial render
        const unlink = listen(({ pathname }) => handlePageView(pathname));
        return () => unlink();
    },[]);

    return (
        <div className="App">
            { children }
        </div>
    )
}