import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// Enums
import { GenericIconTypes } from '../enums/GenericIconTypes';
import { AnimationTypes } from '../enums/AnimationTypes';
import { SocialNetworkTypes } from '../enums/SocialNetworkTypes';

// Assets
import '../assets/styles/AboutPanel.css';

export const AboutPanel = (props: any) => {

    const { sponsor } = useParams();
    const { Cause, Logo, SocialNetworks, Icons, ShareNetworks } = props;
    const [cause, setCause] = useState(undefined);
    const [socialNetworks, setSocialNetworks] = useState(undefined);
    const [shareNetworks, setShareNetworks] = useState(undefined);
    const [logo, setLogo] = useState(undefined);
    const [causeSponsor, setCauseSponsor] = useState(undefined);
    const [icons, setIcons] = useState(undefined);
    const [shareIcon, setShareIcon] = useState(undefined);
    const [linkIcon, setLinkIcon] = useState(undefined);
    const [shareMenuVisible, setShareMenuVisible] = useState(true);
    const [showCopyMessage, setShowCopyMessage] = useState(false);
    const [currentClass, setCurrentClass] = useState("");
    const [animatingCopy, setAnimatingCopy] = useState(false);
    const [copiedToClipboard, setCopiedToClipboard] = useState(false);

    const GetIcons = () => {
        (icons || []).forEach((icon: any) => {
            if (icon.fields.title === GenericIconTypes.SHARE)
                setShareIcon(icon.fields.file.url);
            if (icon.fields.title === GenericIconTypes.LINK)
                setLinkIcon(icon.fields.file.url);
        })
    }

    const FindCauseSponsor = (cause: any) => {
        (cause.sponsors || []).forEach((causeSponsor: any) => {
            if (causeSponsor.fields.name.toLowerCase() === sponsor.split('_').join(' '))
                setCauseSponsor(causeSponsor.fields);
        })
    }

    const AnimateClipboardCopy = () => {
        setCopiedToClipboard(true);

        if (animatingCopy)
            return;

        setAnimatingCopy(true);
        setShowCopyMessage(true);
        setCurrentClass(AnimationTypes.FADE_IN_NORMAL);
        setTimeout(() => {
            setCurrentClass(AnimationTypes.FADE_OUT_NORMAL);
        }, 1000);
        setTimeout(() => {
            setShowCopyMessage(false);
            setCurrentClass("");
            setAnimatingCopy(false);
            setCopiedToClipboard(false);
        }, 2000)
    }

    const GetShareNetworkShareLink = (networkName: any): string => {
        switch (networkName) {
            case SocialNetworkTypes.FACEBOOK:
                return `https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`;
            case SocialNetworkTypes.INSTAGRAM:
                break;
            case SocialNetworkTypes.TWITTER:
                return `https://twitter.com/intent/tweet?text=${window.location.href}`;
            case SocialNetworkTypes.MEWE:
                return `https://mewe.com/myworld`;
            default:
                return ``                
        }
    }

    useEffect(() => {
        setCause(Cause);
        if (sponsor)
            FindCauseSponsor(Cause);
    }, [Cause])

    useEffect(() => {
        setSocialNetworks(SocialNetworks);
    }, [SocialNetworks])

    useEffect(() => {
        setShareNetworks(ShareNetworks);
    }, [ShareNetworks])

    useEffect(() => {
        setLogo(Logo);
    }, [Logo])

    useEffect(() => {
        setIcons(Icons);
    }, [Icons])

    useEffect(() => {
        GetIcons();
    }, [icons])

    return (
        <section id="AboutPanel">
            <div>
                <h4>Cause Overview</h4>
                {
                    cause
                    &&
                    <React.Fragment>
                        <img src={cause.imgUrl} id="AboutPanel-main-image" />
                        <h1>{ cause.title }</h1>
                        <p>{ cause.description }</p>
                        {
                            !sponsor
                            &&
                            <React.Fragment>
                                <img src={cause.aboutImage} />
                                <p>{ cause.aboutDescription }</p>
                            </React.Fragment>
                        }
                        {
                            shareMenuVisible
                            ?
                            <article id="AboutPanel-share-section-visible" className="animated fadeIn">
                                <span>Share cause</span>
                                <figure>
                                    <article>
                                        <CopyToClipboard text={window.location.href} onCopy={() => AnimateClipboardCopy()}>
                                            <img src={linkIcon} title="Copy link" className="AboutPanel-share-image" />
                                        </CopyToClipboard>
                                        {
                                            copiedToClipboard
                                            &&
                                            showCopyMessage
                                            &&
                                            <figure id="AboutPanel-copy-popup" className={currentClass}>
                                                <span>Copied</span>
                                            </figure>
                                        }
                                    </article>
                                    {
                                        (shareNetworks || []).map((shareNetwork: any, index: number) => (
                                            <React.Fragment>
                                                {
                                                    shareNetwork
                                                    &&
                                                    shareNetwork.id !== SocialNetworkTypes.INSTAGRAM
                                                    &&
                                                    GetShareNetworkShareLink(shareNetwork.id)
                                                    &&
                                                    <a href={GetShareNetworkShareLink(shareNetwork.id)} target="_blank" key={index} rel="noorigin noreferrer"><img src={shareNetwork.icon.fields.file.url} title={`Share on ${shareNetwork.name}`} /></a>
                                                }
                                            </React.Fragment>
                                        ))
                                    }
                                </figure>
                            </article>
                            :
                            <article id="AboutPanel-share-section" onClick={() => setShareMenuVisible(true)}>
                                <span>Share cause</span>
                                <img src={shareIcon} />
                            </article>
                        }
                        {
                            sponsor
                            &&
                            causeSponsor
                            &&
                            <figure id="AboutPanel-cause-sponsor">
                                <p>Cause Sponsor</p>
                                <h1>{ causeSponsor.name }</h1>
                                <aside>
                                    <a href={causeSponsor.url} target="_blank" rel="noorigin noreferrer">{ causeSponsor.url }</a>
                                </aside>
                                <img src={causeSponsor.image.fields.file.url} />
                            </figure>
                        }
                        {/* <h5>Brought to you by:</h5> */}
                        <figure id="AboutPanel-logo-container">
                            {
                                logo
                                &&
                                <a href="http://farstaractionfund.org/" target="_blank" rel="noorigin noreferrer">
                                    <img id="Footer-logo" src={logo} alt="CVV Logo" style={{ width: '8rem', height: '55px', marginLeft: 'auto', marginRight: 'auto' }} />
                                </a>
                            }
                        </figure>
                        {
                            socialNetworks
                            &&
                            <section style={{ width: `${socialNetworks.length * 2.6}rem`}}>
                                {
                                    socialNetworks.map((socialNetwork: any, index: number) => (
                                        <a href={socialNetwork.url} target="_blank" key={index} rel="noorigin noreferrer"><img className="Footer-social-icon" src={socialNetwork.icon.fields.file.url} /></a>
                                    ))
                                }
                            </section>
                        }
                    </React.Fragment>
                }
            </div>
        </section>
    )
}