import React, { useEffect, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
import MetaTags from 'react-meta-tags';

// Components
import { HeaderBar } from '../../components/HeaderBar';
import { Hero } from '../../components/Hero';
import { Causes } from '../../components/Causes';
import { Footer } from '../../components/Footer';
import { contentfulClient } from './../../utils/ContentfulClient';

// Interfaces
import { ContentfulObject } from './../../interfaces/Contentful/ContentfulObject';
import { Cause } from '../../interfaces/Cause';
import { HeroData } from './../../interfaces/HeroData';

// Enums
import { ContentfulContentTypes } from '../../enums/ContentfulContentTypes';
import { LogoTypes } from '../../enums/LogoTypes';

// Assets
import './HomePage.css';

export const HomePage = () => {

    const [heroData, setHeroData] = useState(undefined);
    const [causes, setCauses] = useState([]);
    const [socialNetworks, setSocialNetworks] = useState([]);
    const [logo, setLogo] = useState(undefined);
    const [footerData, setFooterData] = useState(undefined);
    const [loading, setLoading] = useState(true);

    // Grab relevant contentful data
    const GetData = async () => {
        try {
            setLoading(true);
            const response = await contentfulClient.getEntries();
            await new Promise(resolve => {
                response.items.forEach((item: ContentfulObject) => {
                    switch (item.sys.contentType.sys.id) {
                        case ContentfulContentTypes.CAUSES:
                            item.fields.causes.forEach((causeItem: any) => {
                                let cause: Cause = {
                                    imgUrl: causeItem.fields.image.fields.file.url,
                                    title: causeItem.fields.title,
                                    description: causeItem.fields.description,
                                    aboutDescription: undefined,
                                    aboutImage: undefined,
                                    sponsors: undefined,
                                    subCauses: undefined
                                }
                                setCauses(causes => [...causes, cause]);
                            })
                            break;
                        case ContentfulContentTypes.HERO:
                            let heroData: HeroData = {
                                header_1: item.fields.header1,
                                header_2: item.fields.header2,
                                header_3: item.fields.header3,
                                subHeader: item.fields.subHeader,
                                heroImageText: item.fields.image.fields.file.url
                            }
                            setHeroData(heroData);
                            break;
                        case ContentfulContentTypes.SOCIAL_NETWORKS:
                            item.fields.network.forEach((socialNetwork: any) => {
                                if (socialNetwork.fields.active)
                                    setSocialNetworks(socialNetworks => [...socialNetworks, socialNetwork.fields])
                            });
                            break;
                        case ContentfulContentTypes.LOGO:
                            item.fields.logos.forEach((logo: any) => {
                                if (logo.fields.title === LogoTypes.LOGO_WHITE)
                                    setLogo(logo.fields.file.url)
                            });
                            break;
                        case ContentfulContentTypes.FOOTER:
                            setFooterData(item.fields.backgroundImage.fields.file.url);
                            break;
                    }
                })
                resolve();
        })
        setLoading(false);
        } catch(e) {
            setLoading(false);
            console.log(e);
        }
    }

    useEffect(() => {
        GetData();
    }, [])

    return (
        <React.Fragment>
            {
                heroData
                &&
                <MetaTags>
                    <title>{ `Choice Voice Vote - by Far Star Action Fund`}</title>
                    <meta name="Description" content={`Choice Voice Vote helps you make an impactful choice, amplify Your voice, cast your vote. Achieve a positive impact on the causes that mean the most to you!`} ></meta>
                    <meta property="og:locale" content="en_CA"></meta>
                    <meta property="og:type" content="website"></meta>
                    <meta property="og:title" content="Choice Voice Vote"></meta>
                    <meta property="og:description" content={`${heroData.header_1}. ${heroData.header_2}. ${heroData.header_3}`}></meta>
                    <meta property="og:url" content={window.location.href}></meta>
                    <meta property="og:site_name" content={`Choice Voice Vote`}></meta>
                    <meta property="og:image" content={heroData.heroImageText}></meta>
                    <meta property="og:image:secure_url" content={window.location.href}></meta>
                    <meta property="og:image:width" content="208"></meta>
                    <meta property="og:image:height" content="208"></meta>
                    <meta name="twitter:card" content="summary_large_image"></meta>
                    <meta name="twitter:description" content={``}></meta>
                    <meta name="twitter:title" content={`Choice Voice Vote`}></meta>
                    <meta name="twitter:site" content="@"></meta>
                    <meta name="twitter:image" content={heroData.heroImageText}></meta>
                    <meta name="twitter:creator" content="@"></meta>
                </MetaTags>
            }
            {
                loading
                ?
                <LoadingBar
                    height={3}
                    color='#003B71'
                    onRef={(ref: any) => { if (ref) ref.continousStart() } }
                />
                :
                <React.Fragment>
                    <HeaderBar SocialNetworks={socialNetworks} />
                    <section>
                        <Hero Data={heroData} />
                        <Causes Causes={causes} />
                        <Footer SocialNetworks={socialNetworks} Logo={logo} FooterData={footerData} />
                    </section>
                </React.Fragment>
            }
        </React.Fragment>
    )
}
