import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Grid, Row, Col } from 'react-flexbox-grid';

// Assets
import '../assets/styles/Causes.css';

export const Causes = (props: any) => {

    const [causes, setCauses] = useState(undefined);

    useEffect(()=>{
        if (props.Causes)
            setCauses(props.Causes);
    }, [props]);

    return (
        <section id="Causes" className="normal-padding">
            <h1>Our Current Causes</h1>
            <div id="Causes-container">
                {
                    (causes || []).map((cause: any) => (
                        <article className="Causes-cause-item" key={cause.title}>
                            <Link to={`/${cause.title.replace(/ /g,"_")}`}>
                                <figure style={{ backgroundImage: `url(${cause.imgUrl})`, cursor: 'pointer' }} />
                            </Link>
                            <div>
                                <Link to={`/${cause.title.replace(/ /g,"_")}`} style={{ textDecoration: 'none' }}>
                                    <h3>{cause.title}</h3>
                                </Link>
                                <p>{cause.description}</p>
                            </div>
                            <Link to={`/${cause.title.replace(/ /g,"_")}`}><button>Activate your impact</button></Link>
                        </article>
                    ))
                }
            </div>
        </section>
    )
}