import React, { useState, useEffect } from 'react';

// Enums
import { SubCauseTypes } from '../enums/SubCauseTypes';

// Assets
import '../assets/styles/FilterMenu.css';
import { GenericIconTypes } from '../enums/GenericIconTypes';
import { ChildCallbackTypes } from '../enums/ChildCallbackTypes';
import { AnimationTypes } from '../enums/AnimationTypes';

export const FilterMenu = (props: any) => {

    const { DisplayFilter, NotifyParent, Icons } = props;
    const [selectedFilter, setSelectedFilter] = useState(SubCauseTypes.ALL)
    const [icons, setIcons] = useState(undefined);
    const [closeIcon, setCloseIcon] = useState(undefined);
    const [show, setShow] = useState(false);
    const [currentClass, setCurrentClass] = useState("");

    const GetCloseIcon = () => {
        (icons || []).forEach((icon: any) => {
            if (icon.fields.title === GenericIconTypes.CLOSE)
                setCloseIcon(icon.fields.file.url);
        })
    } 

    const HideFilter = () => {
        setCurrentClass(AnimationTypes.FADE_OUT);
        setTimeout(() => {
            setShow(false);
            setCurrentClass("");
            NotifyParent(ChildCallbackTypes.SET_FILTER_MENU, false)
        }, 1000)
    }

    const ShowFilter = () => {
        setCurrentClass(AnimationTypes.FADE_IN);
        setShow(true);
    }

    useEffect(() => {
        setIcons(Icons);
    }, [Icons])

    useEffect(() => {
        GetCloseIcon();
    }, [icons])

    useEffect(() => {
        if (DisplayFilter)
            ShowFilter();

        return
    }, [DisplayFilter])

    useEffect(() => {
        NotifyParent(ChildCallbackTypes.SET_FILTER_VALUE, selectedFilter);

        return
    }, [selectedFilter])

    if (show)
        return (
            <section id="FilterMenu" className={currentClass}>
                <article>
                    {
                        Object.keys(SubCauseTypes).map((type: any) => (
                            <React.Fragment>
                                {
                                    SubCauseTypes[type] === SubCauseTypes.ALL
                                    &&
                                    <hr id="FilterMenu-all-hr" />
                                }
                                <figure key={type}>
                                    <input type="radio" value={SubCauseTypes[type]} name={SubCauseTypes[type]} checked={selectedFilter === SubCauseTypes[type]} onChange={() => setSelectedFilter(SubCauseTypes[type])} />
                                    <label className={`FilterMenu-label-${SubCauseTypes[type].toLowerCase()}`} htmlFor={SubCauseTypes[type]}>{ SubCauseTypes[type].toUpperCase() }</label>
                                </figure>
                            </React.Fragment>
                        ))
                    }
                </article>
                <img src={closeIcon} onClick={HideFilter} />
            </section>
        )
    else
        return null;
}